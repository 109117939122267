<template>
  <div>
    <b-card no-body>
      <validation-observer ref="formRef">
        <b-form @submit.prevent>
          <b-card-body class="mb-2">
              <b-row>
                <!-- Name -->
                <b-col cols="12" md="3">
                  <b-form-group
                    :label="$t('globalSingular.name')"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        size="sm"
                        ref="firstFocusInput"
                        v-model="form.name"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        placeholder="Budgeting Name"
                      />
                      <small class="text-danger">{{ errors[0]}}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Period -->
                <b-col cols="12" md="3">
                  <b-form-group
                    :label="$t('globalSingular.period')"
                    label-for="period"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="period"
                      rules="required"
                    >
                      <v-select
                        inputId="period"
                        class="select-size-sm"
                        v-model="form.year"
                        :options="LOV.year"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Date Start -->
                <b-col cols="12" md="3">
                  <b-form-group
                    :label="$t('globalSingular.type')"
                    label-for="type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="type"
                      rules="required"
                    >
                      <v-select
                        inputId="type"
                        class="select-size-sm"
                        v-model="form.periodType"
                        :options="LOV.periodType"
                        :reduce="field => field.value"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Button Filter -->
                <b-col cols="12" md="3">
                  <b-button block variant="primary" @click="showingForm" class="mt-2 pd-1">
                    {{ $t('globalActions.generate') }}
                  </b-button>
                </b-col>
              </b-row>
          </b-card-body>

          <b-table
            v-if="showForm"
            id="refListTable"
            ref="refListTable"
            :fields="tableColumns"
            :items="form.details"
            sticky-header
            responsive
            primary-key="id"
            show-empty
            small
            :busy="tableBusy"
            :empty-text="$t('globalSingular.noMatching')"
            class="position-relative"
          >

            <template #table-busy>
              <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                <div class="mb-1 text">
                  <b-spinner class="align-middle" />
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
              </div>
            </template>

              <!-- Column: COA -->
            <template #cell(coa)="{ item }">
              <span :class="['d-block', 'text-nowrap', !item.isAccountParent ? 'pl-1' : 'font-weight-bolder' ]">
                {{ item.label }}
              </span>
              <!-- <small class="font-italic">{{ item.transactionTypes.remark_eng }} </small> -->
            </template>

            <!-- Column: code -->
            <template v-for="(period, idxPeriod) in periodItems" v-slot:[`cell(${period.key})`]="{ item, index }" >
              <div :key="period.key" >
                <cleave
                  v-if="!item.isAccountParent"
                  v-model.number="form.details[index].amounts[idxPeriod].amount"
                  :class="['w-auto text-right form-control form-control-sm', periodItems.length <= 2 && 'width-100-per']"
                  :options="numeric"
                  :disabled="actions.isPreview"
                  placeholder="1,000,000"
                />
              </div>
            </template>
          </b-table>

          <b-card-body v-if="showForm">
            <b-row class="my-1">
              <!-- Description Field -->
              <b-col cols="12" md="12">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.notes')"
                  label-for="notes"
                >
                  <b-form-textarea
                    id="notes"
                    v-model="form.remark"
                    rows="1"
                    :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>

          <div class="pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between">
            <b-button
              class="mb-sm-1 mb-md-0"
              variant="outline-primary"
              :to="{ name: 'apps-transactions-budgeting-list' }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
              />
              {{ $t('globalActions.backToList') }}
            </b-button>

            <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BTable,
  // BLink,
  BSpinner
  // BBadge
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import { required } from '@validations'
import { formatCurrency } from '@/utils/formatter'
// import useListTable from '@/comp-functions/useListTable'

import useFormResource from '@/comp-functions/useFormResource'

// to access i18n
// import useLocalization from '@/comp-functions/utils/useLocalization'

const monthly = [
  { key: '1', label: 'January', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '2', label: 'February', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '3', label: 'March', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '4', label: 'April', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '5', label: 'May', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '6', label: 'June', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '7', label: 'July', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '8', label: 'Augustus', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '9', label: 'September', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '10', label: 'October', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '11', label: 'November', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '12', label: 'December', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr' }
]

const quarter = [
  { key: '1', label: 'Quarter 1', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '2', label: 'Quarter 2', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '3', label: 'Quarter 3', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '4', label: 'Quarter 4', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr' }
]

const semester = [
  { key: '1', label: 'Semester 1', thClass: 'text-right', tdClass: 'align-middle text-right' },
  { key: '2', label: 'Semester 2', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr' }
]

const yearly = [{ key: '1', label: 'January - December', thClass: 'text-right', tdClass: 'align-middle text-right' }]

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTable,
    // BLink,
    BSpinner,
    // BBadge,
    vSelect,
    Cleave
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const { $get } = useHttp()
    // const { $t } = useLocalization()

    const {
      paramsId,
      formRef,
      firstFocusInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/budgeting', localeContextPath: 'apps.budgeting.singular.budgeting', redirectPathName: 'apps-transaction-budgeting' })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    const form = ref({
      name: '',
      remark: '',
      periodType: '',
      year: '',
      details: [
        {
          accountId: null,
          subDetails: [
            {
              period: null,
              amount: null
            }
          ]
        }
      ]
    })

    const tableBusy = ref(false)
    const showForm = ref(false)
    const periodItems = ref([])

    const LOV = ref({
      periodType: [
        {
          label: 'MONTHLY',
          value: 'MONTHLY'
        },
        {
          label: 'QUARTER',
          value: 'QUARTER'
        },
        {
          label: 'SEMESTER',
          value: 'SEMESTER'
        },
        {
          label: 'YEARLY',
          value: 'YEARLY'
        }
      ],
      year: [
        2021,
        2022,
        2023,
        2024
      ]
    })

    const tableColumns = ref([{ key: 'coa', label: 'List COA', stickyColumn: true }])

    const getAccounts = async () => {
      const { data } = await $get({ url: 'value/account-profit-loss'})
      return data
    }

    const setPeriodItems = () => {
      if (form.value.periodType === 'MONTHLY') {
        periodItems.value = [...monthly]
        tableColumns.value = [...tableColumns.value, ...monthly]
      }
      if (form.value.periodType === 'QUARTER') {
        periodItems.value = [...quarter]
        tableColumns.value = [...tableColumns.value, ...quarter]
      }
      if (form.value.periodType === 'SEMESTER') {
        periodItems.value = [...semester]
        tableColumns.value = [...tableColumns.value, ...semester]
      }
      if (form.value.periodType === 'YEARLY') {
        periodItems.value = [...yearly]
        tableColumns.value = [...tableColumns.value, ...yearly]
      }
    }

    const showingForm = async () => {
      const validated = await formRef.value.validate()
      if (validated) {
        showForm.value = true
        tableBusy.value = true
        tableColumns.value = [{ key: 'coa', label: 'List COA', stickyColumn: true }]

        const accounts = await getAccounts()
        setPeriodItems()

        form.value.details = []
        accounts.forEach(field => {
          const columns = {}
          columns.amounts = []
          columns.accountId = field.id
          columns.label = field.label
          columns.isAccountParent = field.is_account_parent
          periodItems.value.forEach(item => {
            const amountPeriod = {
              period: item.key,
              amount: null
            }
            columns.amounts.push(amountPeriod)
          })
          form.value.details.push(columns)
        })
        tableBusy.value = false
      }
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const accounts = await getAccounts()
      showForm.value = true
      tableBusy.value = true
      const data = await show()
      form.value.name = data.name
      form.value.periodType = data.period_type
      form.value.year = data.year
      form.value.remark = data.remark
      form.value.details = data.details
      // showForm.value = false
      setPeriodItems()
      accounts.forEach((field, index) => {
        form.value.details[index].label = field.label
        form.value.details[index].isAccountParent = field.is_account_parent
      })

      tableBusy.value = false
      // form.value.details = form.value.details.map(field => {
      //   field.accountId = field.account_id
      //   delete field.account_id
      //   return field
      // })
    }

    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
    })

    return {
      paramsId,
      formRef,
      firstFocusInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      tableBusy,
      showForm,
      showingForm,
      periodItems,
      numeric,
      required,
      form,
      LOV,
      formatCurrency,
      tableColumns
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    async handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>
